<template>
  <div class="login-main">
    <van-nav-bar fixed placeholder title="验证码登录" />

    <van-cell
      :border="false"
      value-class="login-title"
      value="收货单签收系统"
    />

    <van-form @submit="onSubmit">
      <van-field
        v-model="state.username"
        name="username"
        clearable
        placeholder="请输入手机号"
        :rules="[{ pattern, required: true, message: '请填写手机号' }]"
      />

      <van-field
        v-model="state.password"
        center
        clearable
        name="password"
        placeholder="请输入验证码"
        :rules="[{ required: true, message: '请填写验证码' }]"
      >
        <template #button>
          <van-button
            v-if="state.show"
            :disabled="!isDisabled"
            size="small"
            @click="start"
            round
            :color="isDisabled ? '#ff4400' : '#999'"
            style="width: 100px"
          >
            获取验证码
          </van-button>
          <van-button
            style="width: 100px"
            v-show="!state.show"
            size="small"
            round
            color="#cccccc"
          >
            <div class="flex coun-dn">
              重新发送
              <van-count-down
                ref="countDown"
                :time="time"
                :auto-start="false"
                format="ss"
                @finish="onFinish"
              />s
            </div>
          </van-button>
        </template>
      </van-field>

      <div style="margin: 16px" v-if="isDisabled && state.password">
        <van-button round block color="#ff4400" native-type="submit"
          >登录</van-button
        >
      </div>
      <div style="margin: 16px" v-else>
        <van-button round block color="#cccccc">登录</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { reactive, ref, computed } from "vue";
import { getLSt, setLSt } from "@/utils/utis";
import { login, verifyCode } from "@/api/user";
import { useRouter } from "vue-router";
import { Toast } from "vant";
export default {
  setup() {
    const routers = useRouter();
    const state = reactive({
      username: "",
      password: "",
      show: true,
    });

    const getUserinfo = async () => {
      const user = await getLSt("userinfo");
      if(user){
        routers.push({ path: '/home' })
        return
      }
    }

    getUserinfo()

    const pattern = /^1\d{10}$/;
    const onSubmit = async (values) => {
      const data = await login({
        mobile: values.username,
        code: values.password,
      });
      if (data.recode == 200) {
        setLSt("userinfo", JSON.stringify(data));
        setTimeout(function(){
          location.href = "/home";
        }, 100)
        
      } else {
        Toast(data.msg);
      }
    };

    const isDisabled = computed(() => {
      return pattern.test(state.username);
    });

    const countDown = ref(null);
    const time = ref(null);
    const start = async () => {
      const Vcode = await verifyCode(state.username);
      if(Vcode == undefined) return
      time.value = Number(Vcode.time) * 1000;
      countDown.value.reset();
      state.show = false;
      setTimeout(function () {
        countDown.value.start();
      }, 100);
    };

    const onFinish = () => {
      state.show = true;
      countDown.value.reset();
    };

    return {
      time,
      pattern,
      state,
      onSubmit,
      start,
      onFinish,
      countDown,
      isDisabled,
    };
  },
};
</script>

<style lang="less">
.login-main {
  background-color: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  .login-title {
    font-size: 17px;
    text-align: center;
    margin: 50px 0;
  }
  .coun-dn {
    color: #666;
  }
}
</style>
